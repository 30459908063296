import revive_payload_client_EIeauEleWe from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._2lpp4aenwxvctugikhgm2ziaxi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_EqoLDnr2Ez from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._2lpp4aenwxvctugikhgm2ziaxi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_IK0Jr3ZfQ3 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._2lpp4aenwxvctugikhgm2ziaxi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_5paLenP4gp from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._2lpp4aenwxvctugikhgm2ziaxi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_871ftf4mPK from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._2lpp4aenwxvctugikhgm2ziaxi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_xFc7VAjpOO from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._2lpp4aenwxvctugikhgm2ziaxi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_KbemWb6bfB from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._2lpp4aenwxvctugikhgm2ziaxi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/platform-admin/.nuxt/components.plugin.mjs";
import prefetch_client_Ns64g8JMKk from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._2lpp4aenwxvctugikhgm2ziaxi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ywJDomcNte from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.2.11_rollup@4.22.5_webpack-sources@3.2.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import tooltip_rg3DQpchyE from "/vercel/path0/node_modules/.pnpm/@docue+docue-ui-v2@3.2.2_magicast@0.2.11_nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16._z6eja326s6zmqldbqrlubjg4ky/node_modules/@docue/docue-ui-v2/ui-plugins/tooltip.ts";
import bugsnag_tkMQfOjcVZ from "/vercel/path0/apps/platform-admin/plugins/bugsnag.ts";
export default [
  revive_payload_client_EIeauEleWe,
  unhead_EqoLDnr2Ez,
  router_IK0Jr3ZfQ3,
  payload_client_5paLenP4gp,
  navigation_repaint_client_871ftf4mPK,
  check_outdated_build_client_xFc7VAjpOO,
  chunk_reload_client_KbemWb6bfB,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Ns64g8JMKk,
  plugin_ywJDomcNte,
  tooltip_rg3DQpchyE,
  bugsnag_tkMQfOjcVZ
]